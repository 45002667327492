/**
 * Reserve Motorcycle
 */

//Reserve Functions

function updateTotal() {
  var days,
    rate,
    total = 0,
    deposit = 0,
    depositDayRate = 50,
    accessories = "",
    accessoryIds = "";

  if (!checkIfEmpty(".js-datetime")) {
    //get number of days hide price for days selected
    days = updateDayRate();
    copyDate();

    //enable next step
    enableNextStep("motorcycle");

    if ($(".js-motorcycle:checked").length > 0) {
      rate = $(".js-motorcycle:checked + .radio-btn-image .price:visible").data(
        "price"
      );

      $("#js-motorcycle_id").val($(".js-motorcycle:checked").data("entry-id"));
      $("#js-checkout-days").val(days);

      total = rate * days;
      deposit = depositDayRate * days;

      //enable next step
      enableNextStep("accessory");
      enableNextStep("cust-info");

      if ($(".js-accessory:checked").length > 0) {
        //reset variable
        rate = 0;
        $(".js-accessory:checked + .radio-btn-image .price").each(function() {
          rate += $(this).data("price");
          accessories += $(this).data("title") + "|";
          accessoryIds += $(this).data("entry-id") + "|";
        });

        accessories = accessories.slice(0, -1);
        accessoryIds = accessoryIds.slice(0, -1);
        $("#js-accessories").val(accessories);
        $("#js-accessory-ids").val(accessoryIds);

        total = total + rate * days;
      }
    }
  } else {
    resetAllFields();
  }
  $("#js-total-display").html(total.toFixed(2));
  $("#js-total").val(total.toFixed(2));
  $("#js-deposit").val(deposit.toFixed(2));
  $("#js-deposit-display").html(deposit.toFixed(2));
}

//Checks if fields are empty returns true if there is one
function checkIfEmpty(field) {
  var flag = false;
  $(field).filter(function() {
    if (this.value == "") {
      flag = true;
      //no need to iterate further
      return false;
    }
  });

  return flag;
}

//Set today's date
function setPickedDate() {
  checkInDatePicker.set("min", $(".js-checkout-date").val());
}

//Update day rate on motorcycle
function updateDayRate() {
  var startDate = checkOutDatePicker.get("select").pick,
    endDate = checkInDatePicker.get("select").pick,
    startTime = checkOutTimePicker.get("select").pick * 60000,
    endTime = checkInTimePicker.get("select").pick * 60000,
    totalCheckOutMilliseconds,
    priceRange1 = 2,
    priceRange2 = 7,
    priceRange3 = 14;

  $(".js-price-1, .js-price-2, .js-price-3").show();

  //Add time onto days
  endDate = endDate + endTime;
  startDate = startDate + startTime;
  totalCheckOutMilliseconds = endDate - startDate;

  //Convert ms to Days
  //Round up to the next whole day
  numDays = Math.ceil(totalCheckOutMilliseconds / 86400000);

  if (numDays <= priceRange1) {
    $(".js-price-2, .js-price-3").hide();
  } else if (numDays > priceRange1 && numDays < priceRange2) {
    $(".js-price-1, .js-price-3").hide();
  } else {
    $(".js-price-1, .js-price-2").hide();
  }

  return numDays;
}

//Disable All Fields
function resetAllFields() {
  $(".js-motorcycle, .js-accessory, .js-cust-info").attr("disabled", true);
  $(".js-motorcycle:checked, .js-accessory:checked").attr("checked", false);
  $(".js-price-1, .js-price-2, .js-price-3").show();
  $("#js-total-display").html("0.00");
}

//Enable next step
function enableNextStep(step) {
  switch (step) {
    case "motorcycle":
      $(".js-motorcycle").attr("disabled", false);
      $(".js-motorcycle.selected").each(function() {
        $(this).attr("checked", true);
      });
      break;

    case "accessory":
      $(".js-accessory").attr("disabled", false);
      break;

    case "cust-info":
      $(".js-cust-info").attr("disabled", false);
      break;
  }
}

//Copy date
function copyDate() {
  $("#js-checkout-date-value").val(
    checkOutDatePicker.get("select", "yyyy-mm-dd") +
      "T" +
      checkOutTimePicker.get("select", "HH:i") +
      ":00"
  );
  $("#js-checkin-date-value").val(
    checkInDatePicker.get("select", "yyyy-mm-dd") +
      "T" +
      checkInTimePicker.get("select", "HH:i") +
      ":00"
  );
}

if ($(".rental-form").length > 0) {
  //Global Variables
  var startDateValues,
    endDateValues,
    startTimeValues,
    endTimeValues,
    totalCheckOutDays,
    checkOutDatePicker,
    checkInDatePicker,
    checkOutTimePicker,
    checkInTimePicker;

  //Disable all other steps
  resetAllFields();

  //Date picker setup
  var checkOutDate = $(".js-checkout-date").pickadate({
    min: true,
    disable: [
      { from: [2019, 2, 20], to: [2019, 2, 22] },
      { from: [2019, 2, 28], to: [2019, 2, 31] },
      { from: [2019, 3, 5], to: [2019, 3, 10] },
      { from: [2019, 3, 26], to: [2019, 4, 3] },
      { from: [2019, 4, 15], to: [2019, 5, 1] }
    ],
    // disable: [1],
    onClose: function() {
      $(document.activeElement).blur();
      updateTotal();
    },
    onSet: function(context) {
      if (context.select) {
        $(".js-checkout-date").attr("data-date", context.select);
      } else {
        $(".js-checkout-date").removeAttr("data-date");
      }
    }
  });

  var checkInDate = $(".js-checkin-date").pickadate({
    min: true,
    disable: [
      { from: [2019, 2, 20], to: [2019, 2, 22] },
      { from: [2019, 2, 28], to: [2019, 2, 31] },
      { from: [2019, 3, 5], to: [2019, 3, 10] },
      { from: [2019, 3, 26], to: [2019, 4, 3] },
      { from: [2019, 4, 15], to: [2019, 5, 1] }
    ],
    // disable: [1],
    onOpen: function() {
      setPickedDate();
    },
    onClose: function(context) {
      $(document.activeElement).blur();
      updateTotal();
    },
    onSet: function(context) {
      if (context.select) {
        $(".js-checkin-date").attr("data-date", context.select);
      } else {
        $(".js-checkin-date").removeAttr("data-date");
      }
    }
  });
  (checkOutDatePicker = checkOutDate.pickadate("picker")),
    (checkInDatePicker = checkInDate.pickadate("picker"));

  //Time picker setup
  var checkOutTime = $(".js-checkout-time").pickatime({
    // min: Date.now(),
    min: [9, 0],
    max: [16, 30],
    onClose: function() {
      $(document.activeElement).blur();
      updateTotal();
    }
  });

  var checkInTime = $(".js-checkin-time").pickatime({
    // min: new Date(2017, 7, 23, 14),
    min: [9, 0],
    max: [16, 30],
    onClose: function() {
      $(document.activeElement).blur();
      updateTotal();
    }
  });

  (checkOutTimePicker = checkOutTime.pickatime("picker")),
    (checkInTimePicker = checkInTime.pickatime("picker"));
}

//Add selected motorcycle price
$(".js-motorcycle").on("click", function() {
  updateTotal();
});

//Add selected motorcycle accessories
$(".js-accessory").on("click", function() {
  updateTotal();
});

//Combine first and last name
$("#js-first-name, #js-last-name").on("keyup", function() {
  $("#js-name").val($("#js-first-name").val() + " " + $("#js-last-name").val());
});

//Copy all options to meta hidden fields for stripe
$("#js-how-you-heard, #js-other input").on("change", function() {
  var selection = $("#js-how-you-heard").val();

  if (selection == "Other") {
    $("#js-other").show();
  } else {
    $("#js-other").hide();
  }

  $("#js-how-you-heard-value").val(
    selection + " " + $("#js-other input").val()
  );
});

//form validation
if ($(".rental-form").length > 0) {
  $(".rental-form").parsley();
}

//========== End Reserve Motorcycle ====================
