(function() {
  /**
   * Functions
   */

  //Init SLiders
  function initPhotoSlider() {
    $(".js-single-slider-video")
      .not(".slick-initialized")
      .slick({
        arrows: true,
        dots: true
      });
  }

  function initVideosSlider() {
    $(".js-single-slider-photo")
      .not(".slick-initialized")
      .slick({
        arrows: true,
        dots: true
      });
  }

  function largeSliderInit() {
    $(".js-slider-for-large")
      .not(".slick-initialized")
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: ".js-slider-nav-large"
      });

    $(".js-slider-nav-large")
      .not(".slick-initialized")
      .slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: ".js-slider-for-large",
        dots: false,
        centerMode: true,
        centerPadding: 0,
        focusOnSelect: true
      });
  }

  /**
   * Menu Setup
   */

  //Functions
  /**
   * @param  {element} toggle
   */
  function toggleHandler(toggle) {
    toggle.addEventListener("click", function(e) {
      e.preventDefault();
      this.classList.contains("is-active") === true
        ? this.classList.remove("is-active")
        : this.classList.add("is-active");
    });
  }

  //Close mobile nav
  $("#js-nav-close").on("click", function() {
    $("#navbar").collapse("hide");
  });

  //Open navbar
  $(".js-navbar-slide").on("click", function() {
    var $this = $(this),
      menuID = $(this)
        .parents(".js-navbar")
        .data("target"),
      btnID = $(this)
        .parents(".js-navbar")
        .data("button"),
      attribute = $(btnID).attr("aria-expanded");

    $(menuID).slideToggle(function() {
      if (attribute === "true") {
        $(btnID).attr("aria-expanded", "false");
        $(menuID).removeClass("open");

        if ($this.data("no-scroll")) {
          $("body").removeClass("no-scroll");
        }
      } else {
        $(btnID).attr("aria-expanded", "true");
        $(menuID).addClass("open");

        if ($this.data("no-scroll")) {
          $("body").addClass("no-scroll");
        }
      }
    });
  });

  //Close navbar on link click
  $(".navbar a").on("click", function(e) {
    var menuID = $(this)
        .parents(".js-navbar")
        .data("target"),
      btnID = $(this)
        .parents(".js-navbar")
        .data("button"),
      $this = $(this);

    $("body").removeClass("no-scroll");
    $(menuID)
      .slideUp()
      .removeClass("open");

    if ($this.hasClass("noZensmooth")) {
      var height = $(this)
        .closest(".navbar-nav")
        .outerHeight();
      var id = $this.attr("href");
      zenscroll.toY(zenscroll.getTopOf($(id)[0]) - height - 20);
    }

    $(this)
      .closest(".navbar-toggle-btn")
      .addClass("test");
    $(btnID).removeClass("is-active");
    if ($this.hasClass("noZensmooth")) {
      return false;
    }
  });

  //Menu button animation
  var toggles = document.querySelectorAll(".navbar-toggle-btn");

  for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
  }

  //============== End Menu Setup ========================

  /**
   * Adventure Reservation
   */

  //Adventure Reservation Functions
  function updateAdvTotals() {
    var price = $(".js-occupancy:checked").data("price"),
      bikePrice = $(".js-motorcycle-adv:checked").data("price"),
      total;

    total = parseInt(price) + parseInt(bikePrice);

    $(".js-total").val(total.toFixed(2));
    $(".js-total-display").html(total.toFixed(2));
  }

  //initlize total on page load.
  updateAdvTotals();

  $(".js-motorcycle-adv").on("click", function() {
    updateAdvTotals();
  });

  $(".js-occupancy").on("change", function() {
    updateAdvTotals();
  });

  //update title hidden field
  $("#js-last-name").on("change", function() {
    var date = Date.now();

    $(".js-title-out").val(
      $("#js-last-name").val() + "-" + $("#js-title").val() + "-" + date
    );
  });

  //========== End Adventure Reservation  ====================

  /**
   * Adventure Reservation
   */

  //update title hidden field
  $("#js-storage-last-name").on("change", function() {
    var date = Date.now();

    $(".js-title-out").val(
      $("#js-storage-last-name").val() + "-Storage-" + date
    );
  });

  //========== End Adventure Reservation  ====================

  /**
   * Slider setup hero on homepage
   */
  $("#js-main-slider").on("beforeChange", function(event, slick) {
    var currentSlide, slideType, player, command;

    //find the current slide element and decide which player API we need to use.
    currentSlide = $(slick.$slider).find(".slick-current");

    //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
    slideType = currentSlide.attr("class").split(" ")[1];

    //get the iframe inside this slide.
    player = currentSlide.find("iframe").get(0);

    if (slideType == "vimeo") {
      command = {
        method: "pause",
        value: "true"
      };
    } else {
      command = {
        event: "command",
        func: "pauseVideo"
      };
    }

    //check if the player exists.
    if (player != undefined) {
      //post our command to the iframe.
      player.contentWindow.postMessage(JSON.stringify(command), "*");
    }
  });

  $("#js-main-slider").on("init", function(
    event,
    slick,
    currentSlide,
    nextSlide
  ) {
    $("video").each(function() {
      $(this)
        .get(0)
        .play();
    });
  });

  $("#js-main-slider").slick({
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    lazyLoad: "progressive",
    infinite: true,
    speed: 500,
    fade: true,
    dots: true
  });

  //========== End Slider setup hero on homepage  ====================

  /**
   * Slider setup motorcycle slider on homepage
   */

  $("#js-mc-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: "#js-mc-thumbs-slider",
    adaptiveHeight: true
  });
  $("#js-mc-thumbs-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: "#js-mc-slider",
    dots: false,
    centerMode: true,
    centerPadding: 0,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  });

  //========== End Slider setup motorcycle slider on homepage  ====================

  /**
   * Slider setup for top of Adv, Event pages
   */

  /**
   * URL Path
   * @return {array} path
   */
  function urlPath() {
    var url = window.location.pathname.split("/");
    return url;
  }

  /**
   * Ajax load page
   */
  var $spinner = $(".js-loading"),
    $container = $("#js-entry-content");

  function ajaxLoadEntry(url, title, urlTitle) {
    // $container.fadeOut().html("");
    // $spinner.show();

    // $.get(url, function(data) {
    //   $container.html(data);

    //   $spinner.hide();
    //   $container.fadeIn(function() {});

    //   initVideosSlider();
    //   initPhotoSlider();
    //   largeSliderInit();

    //   if (urlTitle !== undefined) {
    //     document.title = title;
    //     history.pushState({}, title, urlTitle);
    //   }
    // });
    $container.load(url, function() {
      // $container.hide();

      if (urlTitle !== undefined) {
        document.title = title;
        history.pushState({}, title, urlTitle);
      }

      hideLoading();

      initVideosSlider();
      initPhotoSlider();
      largeSliderInit();
    });
  }

  function showLoading() {
    $container.fadeOut().html("");
    $spinner.show();
  }

  function hideLoading() {
    $spinner.hide();
    $container.fadeIn();
  }

  if ($("#js-ajax-slide").length > 0) {
    var $ajaxSlider = $("#js-ajax-slide");

    var url = urlPath();
    slideCount = 0;

    $(".js-ajax-slide .item").each(function() {
      if ($(this).data("url-title") === url[2]) {
        return false;
      }
      slideCount++;
    });

    $ajaxSlider.slick({
      arrows: true,
      dots: true,
      initialSlide: slideCount,
      adaptiveHeight: true
    });

    ajaxLoadEntry($(".slick-active").data("ajax-load"));

    $ajaxSlider.on("beforeChange", function() {
      showLoading();
    });

    $ajaxSlider.on("afterChange", function() {
      ajaxLoadEntry(
        $(".slick-active").data("ajax-load"),
        $(".slick-active").data("title"),
        $(".slick-active").data("url-title")
      );
    });

    $(document).on("click", ".js-ajax-link", function(e) {
      e.preventDefault();
      showLoading();
      ajaxLoadEntry(
        $(this).data("ajax-load"),
        $(this).data("title"),
        $(this).data("url-title")
      );
      zenscroll.to(document.getElementById("top"));
    });
  }

  /**
   * Window resize load sliders
   */
  var resizeTimer;

  $(window).on("resize", function(e) {
    clearTimeout(resizeTimer);
    console.log("resize");
    resizeTimer = setTimeout(function() {
      initVideosSlider();
      initPhotoSlider();
      largeSliderInit();
    }, 250);
  });

  if ($(".js-single-slider").length > 0) {
    $(".js-single-slider").slick({
      arrows: true,
      dots: true
    });
  }

  //Video slider pause
  //bind our event here, it gets the current slide and pauses the video before each slide changes.
  $(".js-single-slider-video").on("beforeChange", function(event, slick) {
    var currentSlide, slideType, player, command;

    //find the current slide element and decide which player API we need to use.
    currentSlide = $(slick.$slider).find(".slick-current");

    //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
    slideType = currentSlide.data("video-source");

    //get the iframe inside this slide.
    player = currentSlide.find("iframe").get(0);

    if (slideType == "vimeo") {
      command = {
        method: "pause",
        value: "true"
      };
    } else {
      command = {
        event: "command",
        func: "pauseVideo"
      };
    }

    //check if the player exists.
    if (player != undefined) {
      //post our command to the iframe.
      player.contentWindow.postMessage(JSON.stringify(command), "*");
    }
  });

  if ($(".js-facility").length > 0) {
    largeSliderInit();
  }
  //End Slider Setup

  /**
   * Functions
   */

  //Toggle Menu
  function toggleMenu(menu, button) {
    $(menuID).slideToggle(function() {
      if (attribute === "true") {
        $this.attr("aria-expanded", "false");
        $(menuID).removeClass("open");

        if ($this.data("no-scroll")) {
          $("body").removeClass("no-scroll");
        }
      } else {
        $this.attr("aria-expanded", "true");
        $(menuID).addClass("open");

        if ($this.data("no-scroll")) {
          $("body").addClass("no-scroll");
        }
      }
    });
  }

  /**
   * Storage Motorcycle
   */

  if ($(".storage-form").length > 0) {
    //Date picker setup
    var checkInDateStorage = $(".js-checkin-date").pickadate({
      min: true,
      onClose: function(context) {
        $(document.activeElement).blur();
      },
      onSet: function(context) {
        if (context.select) {
          $(".js-checkin-date").attr("data-date", context.select);
        } else {
          $(".js-checkin-date").removeAttr("data-date");
        }
      }
    });
    var checkOutDateStorage = $(".js-checkout-date").pickadate({
      min: true,
      onOpen: function() {
        setPickedDateStorage();
      },
      onClose: function() {
        $(document.activeElement).blur();
      },
      onSet: function(context) {
        if (context.select) {
          $(".js-checkout-date").attr("data-date", context.select);
        } else {
          $(".js-checkout-date").removeAttr("data-date");
        }
      }
    });

    (checkOutDateStoragePicker = checkOutDateStorage.pickadate("picker")),
      (checkInDateStoragePicker = checkInDateStorage.pickadate("picker"));

    //Set today's date
    function setPickedDateStorage() {
      checkOutDateStoragePicker.set("min", $(".js-checkin-date").val());
    }

    //form validation
    $(".storage-form").parsley();
  }

  //Ajax load content
  $(".js-ajax-load").on("click", function(e) {
    var url = $(this).attr("href");

    $("#js-entry-content").load(url, function() {
      initVideosSlider();
      initPhotoSlider();
    });

    e.preventDefault();
  });

  //Hides FAQ answers
  if ($(".js-faq-question").length > 0) {
    console.log("ready");
    $(".js-faq-answer").each(function() {
      $(this).hide();
    });
  }

  //Show/hide FAQ on click
  $(".js-faq-question").on("click", function() {
    $(this)
      .toggleClass("open")
      .next()
      .slideToggle();
  });
})();
